<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer :title="`${info.id===0?'添加':'编辑'}试剂`" width="32%" placement="right" :closable="true"
                  :visible="visible" @close="onClose">

            <div>
                <a-form-model :model="form" :label-col="{ span: 6, offset: 0 }" :wrapper-col="{ span:10 }">

                    <a-form-model-item label="试剂组别">
                        <a-select placeholder="请选择" v-model="info.group_id">
                            <a-select-option v-for="(item,key) in shijizubie_list" :value="item.id" :tab="item.name">
                                {{item.name}}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-form-model-item label="试剂名称">
                        <a-input v-model="info.name" @blur="inputBlur"/>
                    </a-form-model-item>

                    <a-form-model-item label="拼音代码">
                        <a-input v-model="info.pinyin"/>
                    </a-form-model-item>

                    <a-form-model-item label="试剂规格">
                        <a-input v-model="info.spec"/>
                    </a-form-model-item>

                    <a-form-model-item label="单位">
                        <a-select placeholder="请选择" v-model="info.reagent_unit_id">
                            <a-select-option :key="key" :value="item.id" v-for="(item,key) in shijidanwei_list">
                                {{item.name}}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-form-model-item label="生产厂商">
                        <a-select v-model="info.manufacturer_id">
                            <a-select-option :key="key" :value="item.id" v-for="(item,key) in changjia_list">
                                {{item.short_name}}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-form-model-item label="批准文号">
                        <a-input v-model="info.approval_number"/>
                    </a-form-model-item>

                    <a-form-model-item label="生产批号">
                    <a-input v-model="info.batch"/>
                    </a-form-model-item>

                    <a-form-model-item label="条形码">
                        <a-input v-model="info.bar_code"/>
                    </a-form-model-item>

                    <a-form-model-item label="试剂有效期">
                        <a-date-picker v-model="info.valid_time"  format="YYYY-MM-DD"  valueFormat="YYYY-MM-DD"/>
                    </a-form-model-item>

                    <a-form-model-item label="使用状态">
                        <a-select  v-model="info.status" @change="handleChange">
                            <a-select-option :value="0">正常使用</a-select-option>
                            <a-select-option :value="1">暂停使用</a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-form-model-item label="价格">
                        <a-input v-model="info.price"/>
                    </a-form-model-item>

                    <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
                        <a-button @click="doClick()" type="primary">保存</a-button>
                    </a-form-item>

                </a-form-model>
            </div>

        </a-drawer>
    </div>
</template>
<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'edit_info', 'edit_time'],

        data: () => ({
            Py_list: '',
            shijizubie_list: [],
            changjia_list: [],
            shijidanwei_list: [],

            info: {
                id: 0,
                name: '',
                pinyin: '',
                spec: '',
                reagent_unit_id: '',
                group_id: '',
                manufacturer_id: '',
                approval_number: '',
                batch:'',
                bar_code: '',
                //Inventory_online: '',
                //Inventory_offline: '',
                status: '',
                price: '',
                valid_time: '',
            },
            do_type: false,
            form: {},
            labelCol: {span: 6},
            wrapperCol: {span: 14},
        }),


        watch: {
            edit_time() {
                this.info = JSON.parse(JSON.stringify(this.edit_info))
            },
        },

        mounted() {
            this.Sampletypelist()
            this.info = JSON.parse(JSON.stringify(this.edit_info))
            this.Manufacturerlist()
            this.Sampletypelist_group()
        },

        methods: {

            inputBlur() {
                let name = this.info.name;
                this.$sa0.post({
                    url: this.$api('Py'),
                    data: {
                        name: name,
                    },

                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.info.pinyin = response.data.pinyin_name
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //获取拼音首字母
            Py() {
                this.$sa0.post({
                    url: this.$api('Py'),
                    data: {
                        name: this.info.name,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Py_list = response.data.list;
                            //console.log(this.Py_list)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //获取生产厂家列表
            Manufacturerlist() {
                this.$sa0.post({
                    url: this.$api('Manufacturerlist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.changjia_list = response.data.list;
                            console.log(this.changjia_list)
                            //this.count = response.data.result.count;//分页
                            //this.pagesize = response.data.result.pagesize;//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //获取试剂组别列表
            Sampletypelist_group() {
                this.$sa0.post({
                    url: this.$api('Sampletypelist'),
                    data: {
                        type: '4',
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            // console.log('-----------------11111-------------',response.data)
                            this.shijizubie_list = response.data.list;
                            //console.log('-----------------', this.shijizubie_list)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //获取试剂单位列表
            Sampletypelist() {
                this.$sa0.post({
                    url: this.$api('Sampletypelist'),
                    data: {
                        type: '5',
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            // console.log('-----------------11111-------------',response.data)
                            this.shijidanwei_list = response.data.list;
                            // console.log('-----------------', this.shijidanwei_list)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //添加试剂
            doClick() {
                let data = {
                    id: this.info.id,
                    name: this.info.name,
                    pinyin: this.info.pinyin,
                    spec: this.info.spec,
                    reagent_unit_id: this.info.reagent_unit_id,
                    group_id: this.info.group_id,
                    manufacturer_id: this.info.manufacturer_id,
                    approval_number: this.info.approval_number,
                    batch:this.info.batch,
                    bar_code: this.info.bar_code,
                    //Inventory_online: this.info.Inventory_online,
                    //Inventory_offline: this.info.Inventory_offline,
                    status: this.info.status,
                    price: this.info.price,
                    valid_time: this.info.valid_time,
                }
                console.log(data)
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.info.id !== 0 ? this.$api('Edit_reagentinfo') : this.$api('Create_reagentinfo'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            this.close(this.info.group_id)
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            //select
            handleChange(value) {
                console.log(`selected ${value}`);
            },

            // 定义 组件 自身的关闭函数
            onClose() {
                this.close(this.info.group_id);
                // this.Reagent_infostock()
            },

            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields((err, values) => {
                    if (!err) {
                        console.log('Received values of form: ', values);
                    }
                });
            },

            normFile(e) {
                console.log('Upload event:', e);
                if (Array.isArray(e)) {
                    return e;
                }
                return e && e.fileList;
            },
        }
    }
</script>

<style scoped>
    #components-form-demo-validate-other .dropbox {
        height: 180px;
        line-height: 1.5;
    }
</style>
