<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer title="试剂入库" width="36%" placement="right" :closable="true" :visible="visible" @close="onClose">

            <div>
                <p class="font-bold">试剂信息</p>
                <table>
                    <tr>
                        <td class="tag">试剂组别</td>
                        <td>{{ruku_info.group_name}}</td>
                    </tr>
                    <tr>
                        <td class="tag">试剂名称</td>
                        <td>{{ruku_info.name}}</td>
                    </tr>
                    <tr>
                        <td class="tag">拼音代码</td>
                        <td>{{ruku_info.pinyin}}</td>
                    </tr>
                    <tr>
                        <td class="tag">生产厂家</td>
                        <td>{{ruku_info.full_name}}</td>
                    </tr>
<!--                    <tr>-->
<!--                        <td class="tag">批准文号</td>-->
<!--                        <td>{{ruku_info.approval_number}}</td>-->
<!--                    </tr>-->
                    <tr>
                        <td class="tag">试剂规格</td>
                        <td>{{ruku_info.spec}}</td>
                    </tr>
                    <tr>
                        <td class="tag">条形码</td>
                        <td>{{ruku_info.bar_code}}</td>
                    </tr>
                </table>
                <a-form-model :label-col="{ span: 4, offset: 0 }" :wrapper-col="{ span:8 }" :model="form">

                    <a-form-model-item label="入库时间">
                        <a-date-picker v-model="info.do_time"  show-time format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss"/>
                    </a-form-model-item>

                    <a-form-model-item label="入库数量">
                        <a-input v-model="info.stock_num"/>
                    </a-form-model-item>

                    <a-form-model-item label="存放地点">
                        <a-select  v-model="info.storage_location" placeholder="请选择存放地点" @change="handleChange">
                            <a-select-option :key="key" :value="item.id" v-for="(item,key) in cunfang_list">
                                {{item.name}}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-form-model-item label="批准文号">
                        <a-input v-model="info.approval_number"/>
                    </a-form-model-item>

                    <a-form-model-item label="生产批号">
                        <a-input v-model="info.batch"/>
                    </a-form-model-item>

                    <a-form-model-item label="试剂有效期">
                        <a-date-picker v-model="info.valid_time"  format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
                    </a-form-model-item>

                    <a-form-model-item label="库存上限">
                        <a-input v-model="info.Inventory_online"/>
                    </a-form-model-item>

                    <a-form-model-item label="库存下限">
                        <a-input v-model="info.Inventory_offline"/>
                    </a-form-model-item>

                    <a-form-model-item label="试剂单价">
                        <a-input placeholder="请填写试剂单价" v-model="info.price"/>
                    </a-form-model-item>
                    <a-form-model-item label="总金额">
                        ￥{{ info_total }}
                    </a-form-model-item>
                    <a-form-item :wrapper-col="{ span: 12, offset: 4 }">
                        <a-button type="primary" html-type="submit" @click="doClick()">确认入库</a-button>
                    </a-form-item>
                </a-form-model>
            </div>

        </a-drawer>
    </div>
</template>
<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'edit_time', 'ruku_info'],
        name: 'about',
        data: () => ({

            options: [],
            renyuan_list: [],
            info: {
                reagent_info_id: 0,
                stock_num: '',
                type: 0,
                batch: '',
                ordernum: '',
                reagent_info_name: '',
                price: '',
                do_time: '',
                reason_id: '',
                storage_location: '',
                reagent_stock_id: '',
                Inventory_online:'',
                Inventory_offline:'',
            },
            cunfang_list: [],
            Create_reagentstock_list: [],
            // value:'',
            labelCol: {span: 6},
            wrapperCol: {span: 14},
            form: {},

        }),
        beforeCreate() {
            this.form = this.$form.createForm(this, {name: 'validate_other'});
        },

        watch: {

            edit_time() {
                this.info = JSON.parse(JSON.stringify(this.ruku_info))
            },
        },

        mounted() {

            this.info.reagent_info_id = this.ruku_info.id;
            this.info.reagent_info_name = this.ruku_info.name;
            this.info.price = this.ruku_info.price;
            this.info.valid_time = this.ruku_info.valid_time;
            this.info.approval_number = this.ruku_info.approval_number;
            this.info.batch = this.ruku_info.batch;
            this.info.bar_code = this.ruku_info.bar_code;
            this.info.full_name = this.ruku_info.full_name

            console.log(this.ruku_info.valid_time)
            //this.info.batch = this.ruku_info.batch;
            if (this.info.do_time == '') {
                this.info.do_time = this.formatDateTime(new Date() / 1)
            }
            if (this.info.valid_time == '') {
                this.info.valid_time = this.formatDateTime(new Date() / 1)
            }
            this.Sampletypelist()//获取存放地点
        },

        computed:{
            info_total(){
                return (this.info.stock_num*this.info.price).toFixed(2)
            },
        },


        methods: {

            // 类型转换
            // wordType(status) {
            //     let type_arr = ['正常使用', '暂停使用', '失效', '临效', '超上限', '超下限', '零库存']
            //     if (type_arr.length >= Number(status)) {
            //         return type_arr[Number(status) - 0]
            //     } else {
            //         return status
            //     }
            // },

            //显示当前时间
            formatDateTime(inputTime) {
                var date = new Date(inputTime);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                var d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                var h = date.getHours();
                h = h < 10 ? ('0' + h) : h;
                var minute = date.getMinutes();
                var second = date.getSeconds();
                minute = minute < 10 ? ('0' + minute) : minute;
                second = second < 10 ? ('0' + second) : second;
                return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;

            },

            //入库
            doClick() {
                let data = {
                    reagent_info_id: this.info.reagent_info_id,
                    stock_num: this.info.stock_num,
                    type: 0,
                    ordernum: this.info.ordernum,
                    reagent_info_name: this.info.reagent_info_name,
                    price: this.info.price,
                    do_time: this.info.do_time,
                    reason_id: this.info.reason_id,
                    storage_location: this.info.storage_location,
                    reagent_stock_id: this.info.reagent_stock_id,
                    Inventory_online: this.info.Inventory_online,
                    Inventory_offline: this.info.Inventory_offline,
                    manufacturer_id: this.info.manufacturer_id,
                    approval_number: this.info.approval_number,
                    batch: this.info.batch,
                    valid_time: this.info.valid_time,
                    bar_code: this.info.bar_code,
                    full_name: this.info.full_name,
                }
                console.log(data)
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.$api('Create_reagentstock'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg('已入库')
                            this.close()
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            //获取存放地点列表
            Sampletypelist() {
                this.$sa0.post({
                    url: this.$api('Sampletypelist'),
                    data: {
                        type: 3,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.cunfang_list = response.data.list;
                            //console.log('-----------------', this.cunfang_list)
                            //return
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //select
            handleChange(value) {
                console.log(`selected ${value}`);
            },

            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
                //this.Create_reagentstock();
            },

            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields((err, values) => {
                    if (!err) {
                        console.log('Received values of form: ', values);
                    }
                });
            },
            normFile(e) {
                console.log('Upload event:', e);
                if (Array.isArray(e)) {
                    return e;
                }
                return e && e.fileList;
            },
        }
    }
</script>

<style scoped>
    table {
        margin: 15px auto 15px 0;
        width: 90%;
        border-top: solid 1px #d9d9d9;
        border-left: solid 1px #d9d9d9;
    }

    table td, table th {
        border-right: solid 1px #d9d9d9;
        border-bottom: solid 1px #d9d9d9;
        padding: 10px 15px;
        line-height: 1;
    }

    table th {
        padding: 12px 15px;
        background: #f5f5f5
    }

    table td.tag {
        background: #f5f5f5;
        width: 18%;
        text-align: right;
        font-weight: bold
    }

    #components-form-demo-validate-other .dropbox {
        height: 180px;
        line-height: 1.5;
    }
</style>
