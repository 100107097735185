<template>
    <div class="wraper">
        <div class="wrap-mainer">
            <div class="numsidebar">
                <div class="sidebarmain">
                    <a-tabs :default-active-key="group_id" v-model="group_id" tab-position="left" type="card" @change="tabChange">
                        <a-tab-pane key="" tab="全部试剂"></a-tab-pane>
                        <a-tab-pane v-for="(item,key) in Group_list" :key="item.id" :tab="item.name"></a-tab-pane>
                    </a-tabs>
                </div>
                <div class="addbutton1" style="padding-right: 10px">
                    <a-button type="primary" style="width: 100%" @click="toZubie()">添加组别</a-button>
                </div>

            </div>

            <div class="list-right wrap-container">

                <div v-if="group_id==''">

                    <a-form layout="inline">
                        <a-form-item>
                            <a-select v-model="status" style="width: 160px">
                                <a-select-option value="">显示所有试剂</a-select-option>
                                <a-select-option value="0">显示正常使用试剂</a-select-option>
                                <a-select-option value="1">显示暂停使用试剂</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item>
                            <a-input v-model="pinyin" placeholder="请输入试剂名称或拼音代码" style="width: 220px"/>
                        </a-form-item>
                        <a-form-item>
                            <a-button @click="Clear()" type="danger">清空</a-button>
                        </a-form-item>

                        <a-form-item>

                            <a-button type="primary" @click="showDrawer({
                                id: 0,
                                name: '',
                                pinyin: '',
                                spec: '',
                                reagent_unit_id: '',
                                group_id: '',
                                manufacturer_id:'',
                                approval_number: '',
                                batch:'',
                                bar_code: '',
                                Inventory_online: '',
                                Inventory_offline: '',
                                status: '',
                                price: '',
                                valid_time: '',})">添加试剂基本信息
                            </a-button>


                        </a-form-item>

                        <a-form-item>
                            <span class="font-bold">全部试剂</span>共有 {{count}} 条试剂信息
                        </a-form-item>
                        <a-form-item style="float: right">
                            <a-button type="danger" @click="Shijizubieguanli_export()">导出筛选数据</a-button>
                        </a-form-item>
                    </a-form>

                    <table class="layui-table" lay-size="sm">
                        <tr>
                            <th>试剂名称</th>
                            <th>拼音代码</th>
                            <th>试剂规格</th>
                            <th>单位</th>
                            <th>生产厂家</th>
                            <th>批准文号</th>
                            <th>生产批号</th>
                            <th>条形码</th>
                            <th>使用状态</th>
                            <th>有效期</th>
                            <th>价格</th>
                            <th>操作</th>
                        </tr>
                        <tr v-for="(item,key) in Reagent_list">
                            <td>{{item.name}}</td>
                            <td>{{item.pinyin}}</td>
                            <td>{{item.spec}}</td>
                            <td>{{item.danwei_name}}</td>
                            <td>{{item.full_name}}</td>
                            <td>{{item.approval_number}}</td>
                            <td>{{item.batch}}</td>
                            <td>{{item.bar_code}}</td>
                            <template>
                                <td v-if="item.status === 1 || item.status === 2 || item.status === 5 || item.status === 6" class="red">
                                    {{wordType(item.status)}}
                                </td>
                                <td v-else>{{wordType(item.status)}}</td>
                            </template>
                            <td>{{item.valid_time}}</td>
                            <td>{{item.price}}</td>
                            <td style="text-align: left">
                                <a-button type="link" @click="showDrawer(item)">编辑</a-button>
                                <a-button type="link" @click="showDrawerP(item)">打印标签</a-button>
                                <a-button v-if="item.status === 0 || item.status === 3 || item.status === 4" type="link" @click="showDrawer1(item)" class="yellow">
                                    入库
                                </a-button>

                                <a-button type="link" @click="delClick(item)" class="redcolor">删除</a-button>

                            </td>
                        </tr>
                    </table>
                    <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
                </div>

                <div v-for="(item,key) in Group_list" :key="item.id" v-if="group_id == item.id">
                    <a-form layout="inline">
                        <a-form-item>
                            <a-select v-model="status" style="width: 160px">
                                <a-select-option value="">显示所有试剂</a-select-option>
                                <a-select-option value="0">显示正常使用试剂</a-select-option>
                                <a-select-option value="1">显示暂停使用试剂</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item>
                            <a-input v-model="pinyin" placeholder="请输入试剂名称或拼音代码" style="width: 220px"/>
                        </a-form-item>
                        <a-form-item>
                            <a-button @click="Clear()" type="primary">清空</a-button>
                        </a-form-item>

                        <a-form-item>
                            <a-space>
                                <a-button type="primary" @click="showDrawer({
                                id: 0,
                                name: '',
                                pinyin: '',
                                spec: '',
                                reagent_unit_id: '',
                                group_id: '',
                                manufacturer_id:'',
                                approval_number: '',
                                batch:'',
                                bar_code: '',
                                Inventory_online: '',
                                Inventory_offline: '',
                                status: '',
                                price: '',
                                valid_time: '',})">添加试剂基本信息
                                </a-button>
                            </a-space>
                        </a-form-item>

                        <a-form-item>
                            <span class="font-bold">{{item.name}}</span>共有 {{count}} 条试剂信息
                        </a-form-item>

                        <a-form-item style="float: right">
                            <a-button type="danger" @click="Shijizubieguanli_export()">导出筛选数据</a-button>
                        </a-form-item>
                    </a-form>


                    <table class="layui-table" lay-size="sm">
                        <tr>
                            <th>试剂名称</th>
                            <th>拼音代码</th>
                            <th>试剂规格</th>
                            <th>单位</th>
                            <th>生产厂家</th>
                            <th>批准文号</th>
                            <th>生产批号</th>
                            <th>条形码</th>
                            <th>使用状态</th>
                            <th>有效期</th>
                            <th>价格</th>
                            <th></th>
                        </tr>
                        <tr v-for="(item,key) in Reagent_list" :key="item.id" v-if=" item.group_id == group_id">
                            <td>{{item.name}}</td>
                            <td>{{item.pinyin}}</td>
                            <td>{{item.spec}}</td>
                            <td>{{item.danwei_name}}</td>
                            <td>{{item.full_name}}</td>
                            <td>{{item.approval_number}}</td>
                            <td>{{item.batch}}</td>
                            <td>{{item.bar_code}}</td>
                            <template>
                                <td v-if="item.status === 1 || item.status === 2 || item.status === 5 || item.status === 6" class="red">
                                    {{wordType(item.status)}}
                                </td>
                                <td v-else>{{wordType(item.status)}}</td>
                            </template>
                            <td>{{item.valid_time}}</td>
                            <td>{{item.price}}</td>
                            <td style="text-align: left">
                                <a-button type="link" @click="showDrawer(item)">编辑</a-button>
                                <a-button type="link" @click="showDrawer1(item)" class="yellow">入库</a-button>
                                <a-button type="link" @click="delClick(item)" class="redcolor">删除</a-button>
                            </td>
                        </tr>
                    </table>
                    <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
                </div>

            </div>
        </div>

        <!--添加试剂、编辑试剂-->
        <div v-if="edit_info">
            <labopen :close="onCloseDrawer" :edit_time="edit_time" :edit_info="edit_info" :Group_listItem="Group_listItem" :visible="drawer_visible"></labopen>
        </div>

        <!--入库-->
        <div v-if="ruku_info">
            <labopen1 :close="onCloseDrawer1" :edit_time="edit_time" :ruku_info="ruku_info" :visible="drawer_visible1"></labopen1>
        </div>
        <!--打印条码-->
        <div v-if="print_show">
            <printTagdefault :info="info" :close="onCloseDrawerP" :visible="drawer_visible"></printTagdefault>
        </div>


    </div>
</template>
<script>
    import printTagdefault from './printTag-default/printTag-default.vue';//w选择打印标签
    import labopen from "../../components/drawer/shijizubieguanli-add/shijizubieguanli-add.vue";//添加编辑试剂
    import labopen1 from "../../components/drawer/shijizubieguanli-ruku/shijizubieguanli-ruku.vue"; //入库
    export default {
        components: {
            labopen,
            labopen1,
            printTagdefault,
        },
        data() {
            return {
                info: [],
                print_show: false,
                // input_test: [1, 2, 3, 4, 5],
                ruku_info: false,
                Reagentinfolist_list: [],
                first: true,

                pinyin: '',
                status: '',
                group_id: '',

                page: 1,
                count: 0,
                pagesize: 0,

                edit_info: false,
                edit_time: 0,
                Reagent_list: [],
                Group_list: [],
                tab_active: '1',
                formInline: {},
                startValue: null,
                labelCol: {span: 4},
                wrapperCol: {span: 14},
                form: {},
                // 定义控制抽屉显示的变量
                drawer_visible: false,
                drawer_visible1: false,
                // 定义控制弹窗显示的变量
                modal_visible: false,
                Group_listItem: {
                    name: ''
                },
                page_show: false,

            };
        },
        watch: {
            status() {
                if (this.page_show) this.searchClick(1)
            },
            pinyin() {
                if (this.page_show) this.searchClick(1)
            },

            $route: {
                handler() {
                    this.mountedDo()
                },
                deep: true
            },

        },

        mounted() {
            this.mountedDo()
        },

        methods: {

            // 定义 关闭对话框时的 函数 打印标签
            onCloseDrawerP() {
                this.drawer_visible = false;
                this.print_show = false
            },
            // 定义 打开对话框时的 函数 打印标签
            showDrawerP(item) {
                // if (info.name === '') return layer.msg('请先输入名称')
                //if (info.serial_num === '') return layer.msg('请先输入编号')
                this.info = item
                //this.isDefault = isDefault
                this.drawer_visible = true;
                this.print_show = true
            },

            mountedDo() {
                this.page = Number(this.$route.query.page) || 1;
                this.pinyin = this.$route.query.pinyin || '';
                this.status = this.$route.query.status || '';
                // this.group_id = Number(this.$route.query.group_id) || '';
                this.group_id = this.checkReagentInfo();
                this.Sampletypelist()// w获取试剂组别列表
                this.Get_reagentinfo()// w获取试剂信息分页列表
                setTimeout(() => {
                    this.page_show = true
                }, 100)
            },

            checkReagentInfo() {
                if (this.$route.query.group_id) {
                    if (this.$route.query.group_id === '') {
                        return 0
                    } else {
                        return Number(this.$route.query.group_id)
                    }
                } else {
                    return ''
                }
            },

            //搜索
            searchClick() {
                this.$router.push({
                    query: {
                        status: this.status,
                        pinyin: this.pinyin,
                        group_id: this.group_id,
                        page: 1,
                    }
                })
            },

            Clear() {
                this.status = '';
                this.pinyin = '';
                this.group_id = '';
                this.page = 1;
            },


            toZubie() {
                this.$router.push('/jichuziduan/index?page=1&type=4')
            },


            // 类型转换
            wordType(status) {
                let type_arr = ['正常使用', '暂停使用', '失效', '临效', '超上限', '超下限', '零库存']
                if (type_arr.length >= Number(status)) {
                    return type_arr[Number(status) - 0]
                } else {
                    return status
                }
            },

            // 分页
            onChange(page) {
                this.$router.push({
                    query: {
                        page: page,
                        serial_code: this.serial_code,
                        name: this.name,
                    }
                })
            },

            //试剂组别导出
            Shijizubieguanli_export() {
                console.log(
                    this.status,
                    this.pinyin,
                )
                this.$sa0.down({
                    url: this.$api('Shijizubieguanli_export'),
                    file_name: 'down1.xlsx',
                    data: {
                        status: this.status,
                        pinyin: this.pinyin,
                        group_id: this.group_id
                    },
                },)
            },
            //获取试剂信息分页列表
            Get_reagentinfo() {
                // console.log(JSON.stringify({
                //     page: this.page,
                //     status: this.status,
                //     pinyin: this.pinyin,
                //     group_id: this.group_id
                // }))
                this.$sa0.post({
                    url: this.$api('Get_reagentinfo'),
                    data: {
                        page: this.page,
                        status: this.status,
                        pinyin: this.pinyin,
                        group_id: this.group_id
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Reagent_list = response.data.result.list;
                            //console.log('this.Reagent_list--------', this.Reagent_list)
                            this.count = response.data.result.count;//分页
                            this.pagesize = response.data.result.pagesize;//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //删除
            delClick(item) {
                //console.log(item)
                this.$sa0.post({
                    url: this.$api('Del_reagentinfo'),
                    data: {
                        id: item.id,
                    },

                }).then((response) => {
                    console.log('response-----------------')
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('已删除')
                            this.Get_reagentinfo()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //获取试剂组别列表
            Sampletypelist() {
                this.$sa0.post({
                    url: this.$api('Sampletypelist'),
                    data: {
                        type: 4,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Group_list = response.data.list;
                            this.tab_active = this.Group_list[0].id
                            this.Group_listItem = this.Group_list[0]
                            this.Get_reagentinfo(this.tab_active)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // tab切换
            tabChange(key) {
                this.$router.push({
                    query: {
                        page: 1,
                        group_id: key,
                        status: this.status,
                        pinyin: this.pinyin
                    }
                })
            },


            handleChange(value) {
                console.log(`selected ${value}`);
            },
            onSelect(keys, event) {
                console.log('Trigger Select', keys, event);
            },
            onExpand() {
                console.log('Trigger Expand');
            },


            // 定义 打开抽屉时的 函数 添加试剂
            showDrawer(item) {
                this.edit_info = item
                this.edit_time = new Date() / 1;
                this.drawer_visible = true;
            },
            // 定义 关闭抽屉时的 函数 添加试剂
            onCloseDrawer(group_id) {
                this.Get_reagentinfo(group_id)
                this.drawer_visible = false;
                this.edit_info = false
            },

            // 定义 打开抽屉时的 函数 入库
            showDrawer1(item) {
                // item.group_name = this.Group_listItem.name
                this.ruku_info = item;
                this.edit_time = new Date() / 1;
                this.drawer_visible1 = true;
            },
            // 定义 关闭抽屉时的 函数 入库
            onCloseDrawer1() {
                //this.Create_reagentstock();
                this.drawer_visible1 = false;
                this.ruku_info = false
            },

        },
    };
</script>

<style>
    .red{color: #ff0000}

    .top span{
        margin: 0 10px 0 15px
        }

    .top button{
        /*margin-left: 15px*/
        }


    .list-left{
        background: #f9f9f9;
        min-height: 850px;
        border: 1px solid #eeeeee;
        border-right: none;
        height: 100%;
        padding: 20px 0 0 10px
        }

    .list-right{

        }

    .addbutton{
        width: 100%;
        padding: 0px;
        text-align: center
        }

    .ml20{
        margin-left: 20px
        }

    .list-left{
        background: #f9f9f9;
        min-height: 850px;
        border: 1px solid #eeeeee;
        border-right: none;
        height: 100%;
        padding: 20px 0 0 10px
        }

    .list-right{
        margin-left: 220px;
        }

    .ant-tabs .ant-tabs-left-bar{
        float: right !important;
        margin-right: -1px;
        margin-bottom: 0;
        border-right: 1px solid #e8e8e8;
        width: 100% !important;
        }

    .ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active{
        margin-right: -1px;
        padding-right: 40px !important;
        font-weight: bold;
        }

    .wraper{ width: 100%; height: 100%; display: flex;}

    .wrap-mainer{ flex: 1;display: flex; }

    .numsidebar{ width: 200px; display: flex; flex-direction: column; background: #f9f9f9; padding: 10px; padding-right: 0; position: fixed; height: calc(100vh - 120px)}

    .sidebarmain{ flex: 1; overflow-y: auto}

    .wrap-container{ flex: 1; overflow-y: auto}
</style>
